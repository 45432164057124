<template>
  <div class="vx-row" v-if="Object.keys(product).length > 1">
    <div class="vx-col md:w-4/5">
      <vx-card title="إضافة منتج">
        <div class="mt-5">
          <form-wizard
            color="rgba(var(--vs-primary), 1)"
            :title="null"
            :subtitle="null"
            nextButtonText="التالي"
            backButtonText="السابق"
            finishButtonText="تعديل"
            @on-complete="initializeData"
          >
            <tab-content
              title="بيانات المنتج"
              class="mb-5"
              icon="feather icon-home"
            >
              <!-- tab 1 content -->

              <vs-divider />

              <div class="vx-row">
                <div class="vx-col md:w-1/2 w-full mt-5" id="title">
                  <vs-input
                    label="إسم المنتج"
                    name="الاسم"
                    v-model="product.title"
                    class="w-full"
                    v-validate="'required'"
                  />

                  <span class="text-danger">{{ errors.first("الاسم") }}</span>
                </div>

                <div class="vx-col md:w-1/2 w-full mt-5" id="sku">
                  <vs-input
                    label="رقم الـ sku"
                    name="sku"
                    v-model="product.sku"
                    class="w-full"
                    v-validate="'required'"
                  />

                  <span class="text-danger">{{ errors.first("sku") }}</span>
                </div>

                <div
                  class="vx-col md:w-1/2 w-full mt-5"
                  v-if="addToClassA"
                  id="brand"
                >
                  <vs-select
                    v-model="product.brand_id"
                    name="العلامة التجارية"
                    class="w-full select-large brand"
                    label="العلامة التجارية"
                    v-validate="'required'"
                    @input="getBrand"
                  >
                    <vs-select-item
                      :key="index"
                      :value="item.id"
                      :text="item.name"
                      v-for="(item, index) in brands"
                      class="w-full"
                    />
                  </vs-select>

                  <span class="text-danger">{{
                    errors.first("العلامة التجارية")
                  }}</span>
                </div>

                <div class="vx-col md:w-1/2 w-full mt-5" v-if="addToClassA">
                  <vs-input
                    label="السعر الاصلي"
                    name="السعر الأصلي"
                    @input="calculateSellingPrice"
                    v-model="product.original_price"
                    class="w-full"
                    v-validate="'required|min:0|decimal'"
                  />

                  <span class="text-danger">{{
                    errors.first("السعر الأصلي")
                  }}</span>
                </div>

                <div class="vx-col md:w-1/2 w-full mt-5">
                  <vs-input
                    label="السعر"
                    name="السعر"
                    v-model="product.price"
                    class="w-full"
                    v-validate="'required|min:0|decimal'"
                  />

                  <span class="text-danger">{{ errors.first("السعر") }}</span>
                </div>

                <div class="vx-col md:w-1/2 w-full mt-5">
                  <vs-input
                    label="السعر بعد التخفيض"
                    name="سعر التخفيض"
                    v-model="product.discount_price"
                    class="w-full"
                    v-validate="'min:0|decimal'"
                  />

                  <span class="text-danger">{{
                    errors.first("سعر التخفيض")
                  }}</span>
                </div>

                <div class="vx-col md:w-1/2 w-full mt-5" v-if="addToClassA">
                  <vs-input
                    label="رقم الـ OEM"
                    name="OEM"
                    v-model="product.OEM"
                    class="w-full"
                    v-validate="'required'"
                  />

                  <span class="text-danger">{{ errors.first("OEM") }}</span>
                </div>

                <div class="vx-col md:w-1/2 w-full mt-5" v-if="addToClassA">
                  <vs-input
                    label="رابط المنتج"
                    name="رابط المنتج"
                    v-model="product.url"
                    class="w-full"
                    v-validate="'url'"
                  />

                  <span class="text-danger">{{
                    errors.first("رابط المنتج")
                  }}</span>
                </div>
              </div>
            </tab-content>

            <!-- tab 2 content -->

            <tab-content
              title="تصنيف المنتج"
              class="mb-5"
              icon="feather icon-briefcase"
            >
              <div class="vx-row">
                <div class="vx-col md:w-full w-full mt-5">
                  <vs-select
                    v-model="product.gender_id"
                    name="الجنس"
                    class="w-full select-large"
                    label="الجنس"
                    v-validate="'required'"
                  >
                    <vs-select-item
                      :key="index"
                      :value="item.id"
                      :text="item.name"
                      v-for="(item, index) in options"
                      class="w-full"
                    />
                  </vs-select>

                  <span class="text-danger">{{ errors.first("الجنس") }}</span>
                </div>

                <div class="vx-col md:w-1/2 w-full mt-5">
                  <vs-select
                    v-model="product.category_id"
                    name="التصنيف الرئيسي"
                    class="w-full select-large"
                    label="التصنيف الرئيسي"
                    v-validate="'required'"
                  >
                    <vs-select-item
                      :key="index"
                      :value="item.id"
                      :text="item.name"
                      v-for="(item, index) in categories"
                      class="w-full"
                    />
                  </vs-select>

                  <span class="text-danger">{{
                    errors.first("التصنيف الرئيسي")
                  }}</span>
                </div>

                <div class="vx-col md:w-1/2 w-full mt-5">
                  <vs-select
                    v-model="product.sub_category_id"
                    name="التصنيف الفرعي"
                    class="w-full select-large"
                    label="التصنيف الفرعي"
                    v-validate="'required'"
                  >
                    <vs-select-item
                      :key="index"
                      :value="item.id"
                      :text="item.name"
                      v-for="(item, index) in subCategories"
                      class="w-full"
                    />

                    <span class="text-danger">{{
                      errors.first("التصنيف الفرعي")
                    }}</span>
                  </vs-select>
                </div>

                <div class="vx-col md:w-full w-full">
                  <vs-textarea
                    v-model="product.description"
                    label="وصف مفصّل عن المنتج"
                    class="mb-0 mt-10 mt-6"
                    rows="3"
                    v-validate="'required'"
                  />

                  <span class="text-danger">{{ errors.first("الوصف") }}</span>
                </div>
              </div>
            </tab-content>

            <tab-content
              title="كميات المنتج"
              class="mb-5"
              icon="feather icon-image"
            >
              <div
                class="vx-row vx-col w-full mt-5"
                v-for="(item, index) in product.quantities"
                :key="index"
              >
                <div class="vx-col md:w-1/5">
                  <label for="color">اللون</label><br />

                  <v-swatches
                    v-model="item.color_id"
                    @input="getColor"
                    :swatches="colorValues"
                    row-length="6"
                    id="color"
                    popover-x="left"
                    v-validate="'required'"
                    name="اللون"
                  >
                    <vs-input
                      id=""
                      slot="trigger"
                      :value="item.color_id"
                      class="form__input__element w-full"
                      readonly
                    />
                  </v-swatches>
                  <span class="text-danger">{{
                    errors.first("المقاسات")
                  }}</span>
                </div>

                <div class="vx-row vx-col md:w-2/5">
                  <vs-select
                    v-model="item.type"
                    class="w-full select-large md:w-1/3"
                    label="النوع"
                    @change="clear(item)"
                  >
                    <vs-select-item
                      :key="index"
                      :value="item.value"
                      :text="item.key"
                      v-for="(item, index) in types"
                      class="w-full"
                    />
                  </vs-select>

                  <vs-select
                    v-model="item.size"
                    class="w-full select-large md:w-2/3"
                    label="المقاسات المتوفرة "
                    v-validate="'required'"
                    name="المقاسات"
                  >
                    <vs-select-item
                      :key="index"
                      :value="s"
                      :text="`${s}`"
                      v-for="(s, index) in sizes[item.type]"
                      class="w-full"
                    />
                  </vs-select>
                  <span class="text-danger">{{
                    errors.first("المقاسات")
                  }}</span>
                </div>

                <div class="vx-col md:w-1/5">
                  <vs-input
                    label="الكمية "
                    v-model="item.quantity"
                    class="w-full"
                    v-validate="'required'"
                    name="الكمية"
                  />
                  <span class="text-danger">{{ errors.first("الكمية") }}</span>
                </div>

                <div class="vx-col md:w-1/5 mt-5">
                  <div class="vx-row">
                    <vs-button
                      @click="repeatForm"
                      class="vx-col md:w-3/5 mb-base p-1"
                      type="border"
                      v-if="index == product.quantities.length - 1"
                      icon-pack="feather"
                      icon="icon icon-plus"
                      >كمية اخرى</vs-button
                    >

                    <vs-button
                      @click="deleteRow(index, item.id)"
                      class="vx-col md:w-1/5 mb-base ml-1"
                      type="border"
                      icon-pack="feather"
                      icon="icon icon-x"
                    ></vs-button>
                  </div>
                </div>
              </div>
            </tab-content>

            <!-- tab 3 content -->

            <tab-content
              title="صور المنتج"
              class="mb-5"
              icon="feather icon-image"
            >
              <vs-divider position="left" textClass="text-size" color="primary"
                >تعديل الصور القديمة</vs-divider
              >
              <viewer
                :images="product.medias"
                style="z-index: 9999999"
                class="flex"
              >
                <div
                  class="images mr-2"
                  v-for="(image, index) in product.medias"
                  :key="image.id"
                  style="width: 200px"
                >
                  <vs-button
                    class="close"
                    icon-pack="feather"
                    icon="icon icon-x"
                    id=""
                    @click="spliceFile(index, image.id)"
                  ></vs-button>

                  <img class="image" style="width: 100%" :src="image.path" />
                </div>
              </viewer>
              <vs-divider position="left" textClass="text-size" color="primary"
                >تنزيل صور جديدة</vs-divider
              >

              <div class="vx-row align-items-center justify-evenly">
                <div v-for="(item, index) in product.quantities" :key="index">
                  <div v-if="item.color_id">
                    <label for="color">صور اللون {{ item.color_id }}</label>

                    <VueFileAgent
                      class="mr-5"
                      dir="ltr"
                      :uploadUrl="'https://www.mocky.io/v2/5d4fb20b3000005c111099e3'"
                      ref="vueFileAgent"
                      :theme="'list'"
                      :multiple="true"
                      :deletable="true"
                      @beforedelete="fileDeleted($event, item.color)"
                      :meta="true"
                      :accept="'image/*,.zip'"
                      :maxSize="'5MB'"
                      :maxFiles="8"
                      :helpText="'اختر الصور'"
                      :errorText="{
                        type: 'الرجاء اختيار صور فقط',
                        size: 'الرجاء اختبار صور حجمها لا يزيد عن 5 ميقا بايت',
                      }"
                      v-model="mediaByColor[item.color_id]"
                    >
                    </VueFileAgent>
                  </div>
                </div>
              </div>
            </tab-content>
            <template slot="footer" >
              <div class="wizard-footer-left">
                <wizard-button
                  @click.native="props.prevTab()"
                  v-if="props.activeTabIndex > 0"
                  :style="props.fillButtonStyle"
                  >السابق</wizard-button
                >
              </div>
              <div class="wizard-footer-right">
                <wizard-button
                  v-if="!props.isLastStep"
                  @click.native="props.nextTab()"
                  class="wizard-footer-right"
                  :style="props.fillButtonStyle"
                  >التالي</wizard-button
                >
                <wizard-button
                  @click.native="initializeData()"
                  class="wizard-footer-right finish-button mr-2"
                  :style="props.fillButtonStyle"
                  >تعديل</wizard-button
                >
              </div>
            </template>
          </form-wizard>
        </div>
      </vx-card>
    </div>

    <div class="vx-col md:w-1/5">
      <viewer :images="product.medias" style="cursor: pointer" class="flex">
        <div class="mr-2 mt-4" style="width: 100%; height: 250px">
          <img style="height: 100%" :src="product.medias[0].path" />
        </div>
      </viewer>
      <vx-card title="إضافة الى class a" class="text-center mb-5 mt-2">
        <vs-switch v-model="addToClassA" />
      </vx-card>
      <vx-card
        title="تمييز المنتج"
        class="mb-5 text-center"
        v-if="featuredAccess"
      >
        <vs-switch v-model="product.is_featured" />
      </vx-card>
      <vx-card title="هل المنتج مشحون" class="text-center" v-if="addToClassA">
        <vs-switch v-model="product.is_shipped" />
      </vx-card>
    </div>
  </div>
</template>

<script>
import { FormWizard, TabContent, WizardButton } from "vue-form-wizard";
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import { mapActions } from "vuex";
import "viewerjs/dist/viewer.css";
import { component as Viewer } from "v-viewer";
import VSwatches from "vue-swatches";
import "vue-swatches/dist/vue-swatches.css";
export default {
  components: {
    FormWizard,
    WizardButton,
    TabContent,
    VSwatches,
    Viewer,
  },
  data() {
    return {
      addToClassA: false,
      featuredAccess: this.GetFeaturedAccess(),
      product: { medias: [] },
      categories: [],
      subCategories: [],
      deleted_medias: [],
      deleted_quantities: [],
      mediaByColor: [],
      types: this.getSizeTypes(),
      sizes: this.getSizes(),
    };
  },
  watch: {
    "product.gender_id": async function () {
      await this.changeCategory();
      await this.changeSubCategory();
    },
    "product.quantities": function () {
      this.product.quantities.forEach((quantity) => {
        //getting the color value instead of the color_id so it can be recognized by the vs swatches package
        quantity.color
          ? (quantity.color_id = quantity.color.color_value)
          : (quantity.color_id = "");
        // getting the size type of the quantity size
        Object.keys(this.sizes).forEach((key) => {
          let type = this.sizes[key].find((i) => i == quantity.size);
          if (type) {
            quantity.type = key;
          }
        });
      });
    },
  },
  computed: {
    options() {
      return this.$store.state.products.options;
    },
    brands() {
      return this.$store.state.products.brands;
    },
    colors() {
      return this.$store.state.products.colors;
    },
    offers() {
      return this.$store.state.products.offers;
    },
    colorValues() {
      return this.$store.state.products.colors.map(
        (item) => `#${item.color_value}`
      );
    },
  },
  methods: {
    changeCategory() {
      const data = this.options.find((x) => x.id === this.product.gender_id);
      if (data) {
        this.categories = data.categories;
      }
    },
    displayButton(id) {
      let button = document.getElementById(id);
      button.style.display == "block"
        ? (button.style.display = "none")
        : (button.style.display = "block");
    },
    getBrand(input) {
      // because the component only displays values if its ids and I need the whole brand object so I could display the whole brand
      //original currency and selling currency of the brand
      let brand = this.brands.find((i) => i.id == input);
      this.product.brand = brand;
    },
    spliceFile(index, id) {
      this.deleted_medias.push(id);
      this.product.medias.splice(index, 1);
    },
    changeSubCategory() {
      const data = this.categories.find(
        (x) => x.id === this.product.category_id
      );
      if (data) {
        this.subCategories = data.sub_categories;
      }
    },
    getColor(i) {
      i.replace("#", "");
      this.mediaByColor[i] = [];
    },
    deleteRow(index, id) {
      this.product.quantities.splice(index, 1);
      this.deleted_quantities.push(id);
    },
    repeatForm() {
      this.product.quantities.push({
        id: "",
        size: "",
        color_id: "",
        quantity: "",
        type: "",
      });
    },
    clear(item) {
      item.quantity = "";
      item.size = "";
    },
    ...mapActions("products", [
      "fetchProduct",
      "updateProduct",
      "fetchBrands",
      "fetchColors",
      "fetchOffers",
      "fetchProductOptions",
    ]),
    calculateSellingPrice(original_price) {
      let x = original_price * this.brand.original_currency.rate;
      this.price =
        x * this.brand.selling_currency.rate +
        original_price * (this.brand.increase_percentage / 100);
    },
    initializeData() {
      this.$validator.validateAll().then((result) => {
        if (!result) {
          this.$vs.dialog({
            title: "خطأ في إدخال البيانات",
            text: "هناك خطأ في إدخال البيانات الرجاء التحقق",
            color: "danger",
            acceptText: "إغلاق",
          });
          return;
        } else if (Object.values(this.mediaByColor).flat().length == 0) {
          this.$vs.dialog({
            title: "خطأ في إدخال البيانات",
            text: "يجب إدخال على الاقل صورة واحدة",
            color: "danger",
            acceptText: "إغلاق",
          });
          return;
        } else {
          let bodyFormData = new FormData();
          // since the media object is indexed by the color value (object of media arrays indexed by the color),
          // I need to detach the the object and make it a normal array of objects and push it into the product media array
          Object.keys(this.mediaByColor).forEach((key) => {
            if (this.mediaByColor[key].length) {
              this.mediaByColor[key].forEach((item) => {
                var media = {};
                media.image = item.file;
                media.color_id = key.replace("#", "");
                this.product.medias.push(media);
              });
            }
          });
          this.product.medias.forEach((media, index) => {
            bodyFormData.append("medias[" + index + "][file]", media.image);
            bodyFormData.append("medias[" + index + "][color]", media.color_id);
          });
          //sending the deleted medias with a zero value and the id as the index so the backend knows which one to delete
          this.deleted_medias.forEach((id) => {
            bodyFormData.append("medias[" + id + "][file]", 0);
          });
          //sending the deleted quantity with a zero value and the id as the index so the backend knows which one to delete
          this.deleted_quantities.forEach((id) => {
            bodyFormData.append("quantities[" + id + "]", 0);
          });
          //adding the quantities the new ones and the old ones
          this.product.quantities.forEach((quantity, index) => {
            //I determine the new ones here by making the id as the old one's index so the backend knows which one
            let id = quantity.id ? quantity.id : index;
            bodyFormData.append(`quantities[${id}][size]`, quantity.size);
            bodyFormData.append(
              `quantities[${id}][quantity]`,
              Number(quantity.quantity)
            );
            bodyFormData.append(
              `quantities[${id}][color_id]`,
              quantity.color_id.replace("#", "")
            );
          });
          //making sure that the store has class a access
          if (this.product.brand && this.addToClassA) {
            bodyFormData.set("brand_id", this.product.brand_id);
          }
          bodyFormData.set("_method", "put");
          bodyFormData.set("id", this.$route.params.id);
          bodyFormData.set("title", this.product.title);
          bodyFormData.set("sku", this.product.sku);
          bodyFormData.set("OEM", this.product.OEM);
          bodyFormData.set("url", this.product.url);
          bodyFormData.set("description", this.product.description);
          bodyFormData.set("price", Number(this.product.price));
          bodyFormData.set(
            "original_price",
            Number(this.product.original_price)
          );
          bodyFormData.set(
            "discount_price",
            Number(this.product.discount_price)
          );
          bodyFormData.set("is_shipped", this.product.is_shipped ? 1 : 0);
          bodyFormData.set("is_featured", this.product.is_featured ? 1 : 0);
          bodyFormData.set("gender_id", this.product.gender_id);
          bodyFormData.set("category_id", this.product.category_id);
          bodyFormData.set("sub_category_id", this.product.sub_category_id);
          this.submit(bodyFormData);
        }
      });
    },
    submit(data) {
      this.updateProduct(data)
        .then(() => {
          this.$vs.notify({
            title: "تم بنجاح",
            text: "تم تعديل المنتج بنجاح",
            color: "success",
          });
          let page = this.$route.query.page;
          this.$router.push({
            path: "/stores-management/products",
            query: { page: page },
          });
        })
        .catch((error) => {
          this.$vs.notify({
            title: "حدث خطأ",
            text: Object.values(error.response.data.errors),
            color: "danger",
          });
        });
    },
    setData() {
      this.fetchProduct(this.$route.params.id)
        .then((response) => {
          this.$vs.loading.close();
          this.product = response.data.data;
          this.mediaByColor = this.product.quantities.reduce(
            (group, quantity) => {
              const { color } = quantity;
              const colorValue = color.color_value;
              group[colorValue] = group[colorValue] ? group[colorValue] : [];
              return group;
            },
            {}
          );
        })
        .catch((err) => {
          this.$vs.loading.close();
        });
    },
  },

  created() {
    this.$vs.loading();
    this.fetchBrands();
    this.fetchColors();
    this.fetchOffers();
    this.fetchProductOptions();
    this.setData();
  },
};
</script>

<style lang="scss">
.images {
  width: 40%;
  &:hover {
    opacity: 0.5;
    cursor: pointer;
  }
}

.close {
  cursor: pointer;
  position: absolute;
  background-color: transparent !important;
  color: rgb(255, 255, 255);
  font-weight: 900;
  padding: 0;
  &:hover {
    box-shadow: none !important;
  }
}
.close {
  display: none;
}
.images:hover .close {
  display: block;
}
.viewer-container {
  z-index: 99999999 !important;
}
</style>
